
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedColorsRT () {
    return _createElement('div', { 'className': 'cm_product-colors' }, '\n  Colors in this product include: ', this.colorTiles, '\n  ', _createElement('a', {
        'className': 'cm_related-products-link',
        'href': this.searchLink,
        'target': '_blank'
    }, 'More Like This Color ', _createElement('span', { 'className': 'arrow' }, '\u25B6')));
}
        export const componentNames = []