
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function productDataRT () {
    function scopeNameTerm1(attribute, attributeIndex) {
        var name = attribute.name;
        var term = attribute.term;
        return _createElement('span', {
            'key': name,
            'className': 'cm_browse-coordinating'
        }, _createElement('a', {
            'className': 'cm_related-products-link',
            'href': this.getSearchLink({
                facetSelection: [{
                        field: 'Type',
                        term
                    }]
            }),
            'target': '_blank'
        }, '\n      ', name, ' ', _createElement('span', { 'className': 'arrow' }, '\u25B6')));
    }
    function repeatAttribute2(attribute, attributeIndex, name, term) {
        return scopeNameTerm1.apply(this, [
            attribute,
            attributeIndex
        ]);
    }
    return !this.out_of_stock ? _createElement.apply(this, [
        'div',
        {
            'className': 'cm_coordinating-items',
            'key': '0'
        },
        _createElement('div', {}, 'Browse coordinating'),
        _map([
            {
                name: 'Rugs',
                term: 'Rugs'
            },
            {
                name: 'Pillows',
                term: 'Pillows'
            },
            {
                name: 'Home Decor',
                term: 'Home-Accents'
            },
            {
                name: 'Art',
                term: 'Wall-Art'
            }
        ], repeatAttribute2.bind(this)),
        this.productData.related_collection_url ? _createElement('div', {
            'className': 'cm_browse-coordinating',
            'key': '706'
        }, '\n    Browse the\n    ', _createElement('a', {
            'className': 'cm_related-products-link',
            'href': this.productData.related_collection_url,
            'target': '_blank'
        }, '\n      ', this.productData.related_collection_name, ' collection ', _createElement('span', { 'className': 'arrow' }, '\u25B6'))) : null
    ]) : null;
}
        export const componentNames = []