
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function FacetDialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title-container' }, _createElement('div', { 'className': 'dialog-title' }, 'Filters'), _createElement('div', { 'className': 'total-hits' }, this.totalHits, ' Results')), _createElement('div', { 'className': 'buttons-container' }, _createElement('div', {
        'className': 'cm_button cm_facet-dialog_button-clear btn btn--secondary',
        'onClick': this.clear
    }, '\n          Clear\n        '), _createElement('div', {
        'className': 'cm_button cm_facet-dialog_button-done btn btn--primary',
        'onClick': this.close
    }, '\n          Done\n        '))), this.withColorSearch ? [_createElement('div', {
            'className': 'cm_FacetPanel cm_facet-panel__full-width dialog-body',
            'key': '5991'
        }, [[this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                function repeatI1_reviewChip(i, iIndex) {
                                    return _createElement('span', {
                                        'className': 'icon icon-star-' + (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled'),
                                        'key': `${ this.field }|${ i }`
                                    }, 'filled' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                        'viewBox': '0 0 24 24',
                                        'fill': 'none',
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'key': '526'
                                    }, _createElement('path', {
                                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                        'fill': 'currentColor',
                                        'stroke': 'currentColor',
                                        'strokeWidth': '0.872727',
                                        'strokeMiterlimit': '10',
                                        'strokeLinecap': 'square',
                                        'strokeLinejoin': 'round'
                                    })) : null, 'half' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                        'viewBox': '0 0 24 24',
                                        'fill': 'none',
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'key': '1057'
                                    }, _createElement('path', {
                                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                        'stroke': 'currentColor',
                                        'strokeWidth': '0.872727',
                                        'strokeMiterlimit': '10',
                                        'strokeLinecap': 'square',
                                        'strokeLinejoin': 'round'
                                    }), _createElement('path', {
                                        'd': 'M11.8617 1V16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                        'fill': 'currentColor',
                                        'stroke': 'currentColor',
                                        'strokeWidth': '0.872727',
                                        'strokeMiterlimit': '10',
                                        'strokeLinecap': 'square',
                                        'strokeLinejoin': 'round'
                                    })) : null, 'empty' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                        'viewBox': '0 0 24 24',
                                        'fill': 'none',
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'key': '1796'
                                    }, _createElement('path', {
                                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                        'stroke': 'currentColor',
                                        'strokeWidth': '0.872727',
                                        'strokeMiterlimit': '10',
                                        'strokeLinecap': 'square',
                                        'strokeLinejoin': 'round'
                                    })) : null);
                                }
                                function scopeInchMm1_rangeChip() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '7210'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                }
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '2570'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '), [_createElement('div', {
                                        'className': 'product-rating',
                                        'data-rating-style': 'regular',
                                        'key': '1510'
                                    }, _createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'star-rating product-rating__stars',
                                            'data-rating-style': 'regular'
                                        },
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this))
                                    ]))], this.value !== '5' ? _createElement('span', { 'key': '2344' }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '24430'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : this.template === 'colorChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_colorChip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), '\n  ', this.value, '\n  ', _createElement('span', { 'className': 'cm_chip-color' }, this.colorTile, ' ', this.percent, '%')), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '2640'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                        'className': 'facettitle',
                        'tabIndex': '0'
                    }, 'Current search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                        'div',
                        {
                            'role': 'list',
                            'className': 'cmRepeater_chips'
                        },
                        _map(this.chips, repeatChips1.bind(this))
                    ]), _createElement('div', {
                        'className': 'cm_btn btn btn--primary cm_filter-chips_button',
                        'data-cm-role': 'start-over'
                    }, '\n        Start over\n      '))));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]], [this.facets(function () {
                function repeatFacets1(facets, facetsIndex) {
                    return [facets(function () {
                            function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                return [ShowAlwaysValues(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues2(Values, ValuesIndex) {
                                return [Values(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues3(Values, ValuesIndex) {
                                return [Values(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), !this.isCustomRange ? [
                                            _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem',
                                                'key': '1541'
                                            }, _createElement('span', {}, this.value)),
                                            _createElement('div', {
                                                'className': 'facetentryval',
                                                'key': '1543'
                                            }, this.hitCount)
                                        ] : null, this.isCustomRange ? [_createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem',
                                                'key': '3901'
                                            }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                    }, { count: undefined })];
                            }
                            function scopeDollarInchMm4() {
                                var dollar = [
                                    'price',
                                    'Price'
                                ].includes(this.field) ? '$' : '';
                                var inch = [].includes(this.field) ? '"' : '';
                                var mm = [
                                    'wheel_bore',
                                    'wheel_offset'
                                ].includes(this.field) ? 'mm' : '';
                                return _createElement('div', {
                                    'className': 'cmTemplate_sliderFacet',
                                    'key': '13786'
                                }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                        return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Min',
                                                        'className': 'cm_inputMin'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                            'className': 'separator',
                                            'key': '334'
                                        }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Max',
                                                        'className': 'cm_inputMax'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })]] : null, _createElement('button', {
                                            'type': 'button',
                                            'className': 'cm_btn btn btn--secondary',
                                            'onClick': this.setCustomRange
                                        }, '\n    GO\n  '));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]);
                            }
                            function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                return [ShowAlwaysValues(function () {
                                        function repeatI1(i, iIndex) {
                                            return _createElement('span', {
                                                'className': 'icon icon-star-' + (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled'),
                                                'key': `${ this.field }|${ i }`
                                            }, 'filled' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                'viewBox': '0 0 24 24',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '585'
                                            }, _createElement('path', {
                                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'fill': 'currentColor',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            })) : null, 'half' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                'viewBox': '0 0 24 24',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '1116'
                                            }, _createElement('path', {
                                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            }), _createElement('path', {
                                                'd': 'M11.8617 1V16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'fill': 'currentColor',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            })) : null, 'empty' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                'viewBox': '0 0 24 24',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '1855'
                                            }, _createElement('path', {
                                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            })) : null);
                                        }
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, [_createElement('div', {
                                                'className': 'product-rating',
                                                'data-rating-style': 'regular',
                                                'key': '2100'
                                            }, _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'star-rating product-rating__stars',
                                                    'data-rating-style': 'regular'
                                                },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI1.bind(this))
                                            ]))], this.value !== '5' ? _createElement('span', {
                                            'className': 'cm_and-up',
                                            'key': '2407'
                                        }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues6(Values, ValuesIndex) {
                                return [Values(function () {
                                        function repeatI1(i, iIndex) {
                                            return _createElement('span', {
                                                'className': 'icon icon-star-' + (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled'),
                                                'key': `${ this.field }|${ i }`
                                            }, 'filled' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                'viewBox': '0 0 24 24',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '588'
                                            }, _createElement('path', {
                                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'fill': 'currentColor',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            })) : null, 'half' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                'viewBox': '0 0 24 24',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '1119'
                                            }, _createElement('path', {
                                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            }), _createElement('path', {
                                                'd': 'M11.8617 1V16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'fill': 'currentColor',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            })) : null, 'empty' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                'viewBox': '0 0 24 24',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '1858'
                                            }, _createElement('path', {
                                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            })) : null);
                                        }
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }), [_createElement('div', {
                                                'className': 'product-rating',
                                                'data-rating-style': 'regular',
                                                'key': '2130'
                                            }, _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'star-rating product-rating__stars',
                                                    'data-rating-style': 'regular'
                                                },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI1.bind(this))
                                            ]))], _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, _createElement('div', {
                                'className': 'facettitle',
                                'data-cm-role': 'toggle-facet',
                                'tabIndex': '0'
                            }, _createElement('span', {}, this.name), [this.tooltip(function () {
                                    return _createElement('span', { 'className': 'cm_tooltip' }, _createElement('span', { 'className': 'icon' }, _createElement('svg', {
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'viewBox': '0 0 512 512'
                                    }, _createElement('path', { 'd': 'M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z' }))));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], this.isCollapsed ? [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-angle left',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '39 30 565 565',
                                    'aria-hidden': 'true',
                                    'key': '13040'
                                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-angle down',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '39 30 565 565',
                                    'aria-hidden': 'true',
                                    'key': '16250'
                                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                                'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                'role': 'list'
                            }, this.template === 'simpleFacet' ? _createElement('div', {
                                'className': 'cmTemplate_simpleFacet',
                                'key': '2145'
                            }, this.showFilterInput ? _createElement('div', {
                                'className': 'filter-input',
                                'key': '2231'
                            }, [this.filterInput(function () {
                                    return _createElement('div', {
                                        'className': 'input  cm_filterInput',
                                        'placeholder': 'Enter'
                                    });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], this.inputNotEmpty ? _createElement('span', {
                                'className': 'filter-input_clear-container',
                                'onClick': this.clearInput,
                                'key': '2551'
                            }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                    'className': 'facetdiv',
                                    'key': '27201'
                                }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues2.bind(this))
                            ]), this.needShowMore ? _createElement('div', {
                                'className': 'facetdiv cm_show-all-container',
                                'key': '4551'
                            }, _createElement('a', {
                                'className': 'cm_show-all',
                                'data-cm-role': 'toggle-show-more',
                                'tabIndex': '0'
                            }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                'className': 'cmTemplate_selectFacet',
                                'key': '4877'
                            }, [this.select(function () {
                                    function repeatEntry1(entry, entryIndex) {
                                        return _createElement('option', {
                                            'disabled': this.loading,
                                            'value': entry.term,
                                            'key': entry.value
                                        }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                    }
                                    function repeatEntry2(entry, entryIndex) {
                                        return _createElement('option', {
                                            'disabled': this.loading,
                                            'value': entry.term,
                                            'key': entry.value
                                        }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                    }
                                    function repeatEntry3(entry, entryIndex) {
                                        return _createElement('option', {
                                            'disabled': this.loading,
                                            'value': entry.term,
                                            'key': entry.value
                                        }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                    }
                                    function repeatEntry4(entry, index) {
                                        return _createElement('div', {
                                            'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                            'key': entry.value,
                                            'onClick': () => this.onChange(entry.term)
                                        }, this.showCheckboxes ? _createElement('input', {
                                            'type': 'checkbox',
                                            'readOnly': true,
                                            'checked': entry.selected,
                                            'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                            'key': '3596'
                                        }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                            'className': 'cm_dropdown_hitCount',
                                            'key': '3798'
                                        }, entry.hitCount) : null);
                                    }
                                    return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                        'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                        'key': '62'
                                    }, _createElement('select', {
                                        'className': 'cm_select_inner-select cm_select__pretty',
                                        'onChange': this.onChange,
                                        'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                        'disabled': this.disabled,
                                        'aria-label': this.title
                                    }, this.useNativeDropdown ? [
                                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                            'value': '',
                                            'key': 'null-option'
                                        }, '\n          ', '', this.title, '\n        ') : null,
                                        this.loading ? _createElement('option', {
                                            'key': 'loading-option',
                                            'disabled': true
                                        }, '...loading...') : null,
                                        this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                            _createElement('option', {
                                                'className': 'cm_option_title',
                                                'disabled': true,
                                                'key': '8031'
                                            }, 'Popular Makes'),
                                            _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                            _createElement('option', {
                                                'className': 'cm_option_title',
                                                'disabled': true,
                                                'key': '8034'
                                            }, 'All Makes'),
                                            _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                        ] : null,
                                        this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                    ] : null, !this.useNativeDropdown ? _createElement('option', {
                                        'key': '_current',
                                        'value': '_current'
                                    }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                return _createElement('div', { 'className': 'cm_filterInput' });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                            'aria-label': this.title,
                                            'key': '2892'
                                        },
                                        !this.hideNullOption ? _createElement('div', {
                                            'className': 'option',
                                            'key': 'null-option',
                                            'onClick': () => this.onChange('')
                                        }, '\n      ', '', this.title, '\n    ') : null,
                                        this.loading ? _createElement('div', {
                                            'className': 'option',
                                            'key': 'null-option',
                                            'disabled': true
                                        }, '...loading...') : null,
                                        !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                    ]) : null));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                'className': 'cmTemplate_priceFacet',
                                'key': '10857'
                            }, !this.ranges.length ? [
                                _createElement('div', { 'key': '109411' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                                '\n  ',
                                this.slider,
                                '\n  ',
                                _createElement('div', {
                                    'className': 'cm_flex',
                                    'key': '109413'
                                }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                            ] : null, this.ranges.length ? [_createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cmRepeater_Values',
                                        'key': '112051'
                                    },
                                    _map(this.Values, repeatValues3.bind(this))
                                ])] : null, [this.Inputs(function () {
                                    return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                            return _createElement('div', {
                                                'placeholder': 'Min price',
                                                'className': 'cm_inputMin'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                            return _createElement('div', {
                                                'placeholder': 'Max price',
                                                'className': 'cm_inputMax'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('button', {
                                        'type': 'button',
                                        'className': 'cm_btn btn btn--secondary',
                                        'onClick': this.setCustomRange
                                    }, '\n    GO\n  '));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]) : null, this.template === 'sliderFacet' ? scopeDollarInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                'className': 'cmTemplate_reviewFacet',
                                'key': '15658'
                            }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues6.bind(this))
                            ])) : null, this.template === 'thicknessFacet' ? _createElement('div', {
                                'className': 'cmTemplate_thicknessFacet',
                                'key': '24617'
                            }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], '" to ', this.selectedRange[1], '"\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' })))) : null, this.template === 'advancedColorFacet' ? _createElement('div', {
                                'className': 'cmTemplate_advancedColorFacet',
                                'key': '25032'
                            }, _createElement('div', { 'className': 'base-palette' }, this.paletteItems), _createElement('div', { 'className': 'custom-colors-selector' }, _createElement('div', {
                                'className': 'cm_button btn btn--primary',
                                'onClick': this.openSimplePaletteDialog
                            }, _createElement('span', { 'className': 'icon' }, [_createElement('svg', {
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'viewBox': '0 0 512 512',
                                    'key': '253280'
                                }, _createElement('path', { 'd': 'M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l96 0 32 0 208 0c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z' }))]), '\n    Pick your colors\n  '), _createElement('div', {
                                'className': 'cm_button btn btn--primary',
                                'onClick': this.openColorExtractorDialog
                            }, _createElement('span', { 'className': 'icon' }, [_createElement('svg', {
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'viewBox': '0 0 640 512',
                                    'key': '261400'
                                }, _createElement('path', { 'd': 'M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128l-368 0zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39L296 392c0 13.3 10.7 24 24 24s24-10.7 24-24l0-134.1 39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z' }))]), '\n    Upload Your Color\n  ')), _createElement('div', { 'className': 'cm_color-sliders' }, this.sliders)) : null, this.template === 'sizeFacet' ? _createElement('div', {
                                'className': 'cmTemplate_sizeFacet',
                                'key': '26966'
                            }, [this.WidthFacet(function () {
                                    return _createElement('div', { 'className': 'cm_WidthFacet' }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, _createElement('span', { 'className': 'subsize-title' }, this.name, ': '), !this.isNullRange ? _createElement('span', { 'key': '142' }, this.selectedRange[0], ' - ', this.selectedRange[1]) : null, this.isNullRange ? _createElement('span', { 'key': '235' }, this.selectedRange[0]) : null), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' }))));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], [this.LengthFacet(function () {
                                    return _createElement('div', { 'className': 'cm_LengthFacet' }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, _createElement('span', { 'className': 'subsize-title' }, this.name, ': '), !this.isNullRange ? _createElement('span', { 'key': '143' }, this.selectedRange[0], ' - ', this.selectedRange[1]) : null, this.isNullRange ? _createElement('span', { 'key': '236' }, this.selectedRange[0]) : null), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' }))));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]) : null));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_facets' },
                    _map(this.facets, repeatFacets1.bind(this))
                ]));
            }, {
                widgetName: 'undefined',
                items: undefined
            })])] : null, !this.withColorSearch ? [_createElement('div', {
            'className': 'cm_FacetPanel cm_facet-panel__full-width dialog-body',
            'key': '453991'
        }, [[this.filterChips(function () {
                    function repeatChips1(chips, chipsIndex) {
                        return [chips(function () {
                                function repeatI1_reviewChip(i, iIndex) {
                                    return _createElement('span', {
                                        'className': 'icon icon-star-' + (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled'),
                                        'key': `${ this.field }|${ i }`
                                    }, 'filled' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                        'viewBox': '0 0 24 24',
                                        'fill': 'none',
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'key': '526'
                                    }, _createElement('path', {
                                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                        'fill': 'currentColor',
                                        'stroke': 'currentColor',
                                        'strokeWidth': '0.872727',
                                        'strokeMiterlimit': '10',
                                        'strokeLinecap': 'square',
                                        'strokeLinejoin': 'round'
                                    })) : null, 'half' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                        'viewBox': '0 0 24 24',
                                        'fill': 'none',
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'key': '1057'
                                    }, _createElement('path', {
                                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                        'stroke': 'currentColor',
                                        'strokeWidth': '0.872727',
                                        'strokeMiterlimit': '10',
                                        'strokeLinecap': 'square',
                                        'strokeLinejoin': 'round'
                                    }), _createElement('path', {
                                        'd': 'M11.8617 1V16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                        'fill': 'currentColor',
                                        'stroke': 'currentColor',
                                        'strokeWidth': '0.872727',
                                        'strokeMiterlimit': '10',
                                        'strokeLinecap': 'square',
                                        'strokeLinejoin': 'round'
                                    })) : null, 'empty' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                        'viewBox': '0 0 24 24',
                                        'fill': 'none',
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'key': '1796'
                                    }, _createElement('path', {
                                        'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                        'stroke': 'currentColor',
                                        'strokeWidth': '0.872727',
                                        'strokeMiterlimit': '10',
                                        'strokeLinecap': 'square',
                                        'strokeLinejoin': 'round'
                                    })) : null);
                                }
                                function scopeInchMm1_rangeChip() {
                                    var inch = [].includes(this.field) ? '"' : '';
                                    var mm = [
                                        'wheel_bore',
                                        'wheel_offset'
                                    ].includes(this.field) ? 'mm' : '';
                                    return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '7210'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                }
                                return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '2570'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'reviewChip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_reviewChip' }, _createElement('a', {
                                    'className': 'facetentrykey',
                                    'tabIndex': '0',
                                    'role': 'listitem'
                                }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ': '), [_createElement('div', {
                                        'className': 'product-rating',
                                        'data-rating-style': 'regular',
                                        'key': '1510'
                                    }, _createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'star-rating product-rating__stars',
                                            'data-rating-style': 'regular'
                                        },
                                        _map([
                                            0,
                                            1,
                                            2,
                                            3,
                                            4
                                        ], repeatI1_reviewChip.bind(this))
                                    ]))], this.value !== '5' ? _createElement('span', { 'key': '2344' }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                        'className': 'cm_icon cm_icon-times',
                                        'height': '20px',
                                        'role': 'img',
                                        'viewBox': '0 0 22 22',
                                        'key': '24430'
                                    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                        'className': 'facettitle',
                        'tabIndex': '0'
                    }, 'Current search:'), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                        'div',
                        {
                            'role': 'list',
                            'className': 'cmRepeater_chips'
                        },
                        _map(this.chips, repeatChips1.bind(this))
                    ]), _createElement('div', {
                        'className': 'cm_btn btn btn--secondary cm_filter-chips_button',
                        'data-cm-role': 'start-over'
                    }, '\n        Start over\n      '))));
                }, {
                    widgetName: 'filter-chips',
                    items: undefined
                })]], [this.facets(function () {
                function repeatFacets1(facets, facetsIndex) {
                    return [facets(function () {
                            function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                return [ShowAlwaysValues(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues2(Values, ValuesIndex) {
                                return [Values(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues3(Values, ValuesIndex) {
                                return [Values(function () {
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), !this.isCustomRange ? [
                                            _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem',
                                                'key': '1541'
                                            }, _createElement('span', {}, this.value)),
                                            _createElement('div', {
                                                'className': 'facetentryval',
                                                'key': '1543'
                                            }, this.hitCount)
                                        ] : null, this.isCustomRange ? [_createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem',
                                                'key': '3901'
                                            }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                    }, { count: undefined })];
                            }
                            function scopeDollarInchMm4() {
                                var dollar = [
                                    'price',
                                    'Price'
                                ].includes(this.field) ? '$' : '';
                                var inch = [].includes(this.field) ? '"' : '';
                                var mm = [
                                    'wheel_bore',
                                    'wheel_offset'
                                ].includes(this.field) ? 'mm' : '';
                                return _createElement('div', {
                                    'className': 'cmTemplate_sliderFacet',
                                    'key': '13786'
                                }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm, '\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min, inch, mm), _createElement('div', { 'className': 'max' }, this.max, inch, mm), _createElement('div', { 'className': 'clear' }))), [this.Inputs(function () {
                                        return _createElement('div', { 'className': 'input cm_Inputs' }, this.inputMin ? [[this.inputMin(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Min',
                                                        'className': 'cm_inputMin'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })]] : null, this.inputMin && this.inputMax ? _createElement('span', {
                                            'className': 'separator',
                                            'key': '334'
                                        }, '\u2014') : null, this.inputMax ? [[this.inputMax(function () {
                                                    return _createElement('div', {
                                                        'placeholder': 'Max',
                                                        'className': 'cm_inputMax'
                                                    });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })]] : null, _createElement('button', {
                                            'type': 'button',
                                            'className': 'cm_btn btn btn--secondary',
                                            'onClick': this.setCustomRange
                                        }, '\n    GO\n  '));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]);
                            }
                            function repeatShowAlwaysValues5(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                return [ShowAlwaysValues(function () {
                                        function repeatI1(i, iIndex) {
                                            return _createElement('span', {
                                                'className': 'icon icon-star-' + (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled'),
                                                'key': `${ this.field }|${ i }`
                                            }, 'filled' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                'viewBox': '0 0 24 24',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '585'
                                            }, _createElement('path', {
                                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'fill': 'currentColor',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            })) : null, 'half' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                'viewBox': '0 0 24 24',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '1116'
                                            }, _createElement('path', {
                                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            }), _createElement('path', {
                                                'd': 'M11.8617 1V16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'fill': 'currentColor',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            })) : null, 'empty' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                'viewBox': '0 0 24 24',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '1855'
                                            }, _createElement('path', {
                                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            })) : null);
                                        }
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, [_createElement('div', {
                                                'className': 'product-rating',
                                                'data-rating-style': 'regular',
                                                'key': '2100'
                                            }, _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'star-rating product-rating__stars',
                                                    'data-rating-style': 'regular'
                                                },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI1.bind(this))
                                            ]))], this.value !== '5' ? _createElement('span', {
                                            'className': 'cm_and-up',
                                            'key': '2407'
                                        }, ' and up') : null), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            function repeatValues6(Values, ValuesIndex) {
                                return [Values(function () {
                                        function repeatI1(i, iIndex) {
                                            return _createElement('span', {
                                                'className': 'icon icon-star-' + (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled'),
                                                'key': `${ this.field }|${ i }`
                                            }, 'filled' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                'viewBox': '0 0 24 24',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '588'
                                            }, _createElement('path', {
                                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'fill': 'currentColor',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            })) : null, 'half' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                'viewBox': '0 0 24 24',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '1119'
                                            }, _createElement('path', {
                                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            }), _createElement('path', {
                                                'd': 'M11.8617 1V16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'fill': 'currentColor',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            })) : null, 'empty' === (this.value - i <= 0 ? 'empty' : this.value - i < 0.95 ? 'half' : 'filled') ? _createElement('svg', {
                                                'viewBox': '0 0 24 24',
                                                'fill': 'none',
                                                'xmlns': 'http://www.w3.org/2000/svg',
                                                'key': '1858'
                                            }, _createElement('path', {
                                                'd': 'M11.8617 1L14.4264 8.89151H22.7235L16.0109 13.7685L18.5743 21.66L11.8617 16.783L5.14919 21.66L7.71255 13.7685L1 8.89151H9.29703L11.8617 1Z',
                                                'stroke': 'currentColor',
                                                'strokeWidth': '0.872727',
                                                'strokeMiterlimit': '10',
                                                'strokeLinecap': 'square',
                                                'strokeLinejoin': 'round'
                                            })) : null);
                                        }
                                        return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                            'type': 'checkbox',
                                            'className': 'cm_facet_checkbox',
                                            'readOnly': true,
                                            'checked': this.isSelected
                                        }), _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }), [_createElement('div', {
                                                'className': 'product-rating',
                                                'data-rating-style': 'regular',
                                                'key': '2130'
                                            }, _createElement.apply(this, [
                                                'div',
                                                {
                                                    'className': 'star-rating product-rating__stars',
                                                    'data-rating-style': 'regular'
                                                },
                                                _map([
                                                    0,
                                                    1,
                                                    2,
                                                    3,
                                                    4
                                                ], repeatI1.bind(this))
                                            ]))], _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                    }, { count: undefined })];
                            }
                            return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, _createElement('div', {
                                'className': 'facettitle',
                                'data-cm-role': 'toggle-facet',
                                'tabIndex': '0'
                            }, _createElement('span', {}, this.name), [this.tooltip(function () {
                                    return _createElement('span', { 'className': 'cm_tooltip' }, _createElement('span', { 'className': 'icon' }, _createElement('svg', {
                                        'xmlns': 'http://www.w3.org/2000/svg',
                                        'viewBox': '0 0 512 512'
                                    }, _createElement('path', { 'd': 'M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm169.8-90.7c7.9-22.3 29.1-37.3 52.8-37.3l58.3 0c34.9 0 63.1 28.3 63.1 63.1c0 22.6-12.1 43.5-31.7 54.8L280 264.4c-.2 13-10.9 23.6-24 23.6c-13.3 0-24-10.7-24-24l0-13.5c0-8.6 4.6-16.5 12.1-20.8l44.3-25.4c4.7-2.7 7.6-7.7 7.6-13.1c0-8.4-6.8-15.1-15.1-15.1l-58.3 0c-3.4 0-6.4 2.1-7.5 5.3l-.4 1.2c-4.4 12.5-18.2 19-30.6 14.6s-19-18.2-14.6-30.6l.4-1.2zM224 352a32 32 0 1 1 64 0 32 32 0 1 1 -64 0z' }))));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], this.isCollapsed ? [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-angle left',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '39 30 565 565',
                                    'aria-hidden': 'true',
                                    'key': '13040'
                                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null, !this.isCollapsed ? [_createElement('svg', {
                                    'className': 'cm_icon cm_icon-angle down',
                                    'height': '20px',
                                    'role': 'img',
                                    'viewBox': '39 30 565 565',
                                    'aria-hidden': 'true',
                                    'key': '16250'
                                }, _createElement('path', { 'd': 'M600,189q0-7-6-12l-28-28q-5-6-12-6t-13,6l-220,219-219-219q-5-6-13-6t-12,6l-28,28q-6,5-6,12t6,13l260,260q5,6,12,6t13-6l260-260q6-5,6-13z' }))] : null), _createElement('div', {
                                'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                'role': 'list'
                            }, this.template === 'simpleFacet' ? _createElement('div', {
                                'className': 'cmTemplate_simpleFacet',
                                'key': '2145'
                            }, this.showFilterInput ? _createElement('div', {
                                'className': 'filter-input',
                                'key': '2231'
                            }, [this.filterInput(function () {
                                    return _createElement('div', {
                                        'className': 'input  cm_filterInput',
                                        'placeholder': 'Enter'
                                    });
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], this.inputNotEmpty ? _createElement('span', {
                                'className': 'filter-input_clear-container',
                                'onClick': this.clearInput,
                                'key': '2551'
                            }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                    'className': 'facetdiv',
                                    'key': '27201'
                                }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues2.bind(this))
                            ]), this.needShowMore ? _createElement('div', {
                                'className': 'facetdiv cm_show-all-container',
                                'key': '4551'
                            }, _createElement('a', {
                                'className': 'cm_show-all',
                                'data-cm-role': 'toggle-show-more',
                                'tabIndex': '0'
                            }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'selectFacet' ? _createElement('div', {
                                'className': 'cmTemplate_selectFacet',
                                'key': '4877'
                            }, [this.select(function () {
                                    function repeatEntry1(entry, entryIndex) {
                                        return _createElement('option', {
                                            'disabled': this.loading,
                                            'value': entry.term,
                                            'key': entry.value
                                        }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                    }
                                    function repeatEntry2(entry, entryIndex) {
                                        return _createElement('option', {
                                            'disabled': this.loading,
                                            'value': entry.term,
                                            'key': entry.value
                                        }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                    }
                                    function repeatEntry3(entry, entryIndex) {
                                        return _createElement('option', {
                                            'disabled': this.loading,
                                            'value': entry.term,
                                            'key': entry.value
                                        }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                    }
                                    function repeatEntry4(entry, index) {
                                        return _createElement('div', {
                                            'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                            'key': entry.value,
                                            'onClick': () => this.onChange(entry.term)
                                        }, this.showCheckboxes ? _createElement('input', {
                                            'type': 'checkbox',
                                            'readOnly': true,
                                            'checked': entry.selected,
                                            'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                            'key': '3596'
                                        }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                            'className': 'cm_dropdown_hitCount',
                                            'key': '3798'
                                        }, entry.hitCount) : null);
                                    }
                                    return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                        'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                        'key': '62'
                                    }, _createElement('select', {
                                        'className': 'cm_select_inner-select cm_select__pretty',
                                        'onChange': this.onChange,
                                        'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                        'disabled': this.disabled,
                                        'aria-label': this.title
                                    }, this.useNativeDropdown ? [
                                        !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                            'value': '',
                                            'key': 'null-option'
                                        }, '\n          ', '', this.title, '\n        ') : null,
                                        this.loading ? _createElement('option', {
                                            'key': 'loading-option',
                                            'disabled': true
                                        }, '...loading...') : null,
                                        this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                            _createElement('option', {
                                                'className': 'cm_option_title',
                                                'disabled': true,
                                                'key': '8031'
                                            }, 'Popular Makes'),
                                            _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                            _createElement('option', {
                                                'className': 'cm_option_title',
                                                'disabled': true,
                                                'key': '8034'
                                            }, 'All Makes'),
                                            _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                        ] : null,
                                        this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                    ] : null, !this.useNativeDropdown ? _createElement('option', {
                                        'key': '_current',
                                        'value': '_current'
                                    }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                return _createElement('div', { 'className': 'cm_filterInput' });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                            'aria-label': this.title,
                                            'key': '2892'
                                        },
                                        !this.hideNullOption ? _createElement('div', {
                                            'className': 'option',
                                            'key': 'null-option',
                                            'onClick': () => this.onChange('')
                                        }, '\n      ', '', this.title, '\n    ') : null,
                                        this.loading ? _createElement('div', {
                                            'className': 'option',
                                            'key': 'null-option',
                                            'disabled': true
                                        }, '...loading...') : null,
                                        !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                    ]) : null));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]) : null, this.template === 'priceFacet' ? _createElement('div', {
                                'className': 'cmTemplate_priceFacet',
                                'key': '10857'
                            }, !this.ranges.length ? [
                                _createElement('div', { 'key': '109411' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                                '\n  ',
                                this.slider,
                                '\n  ',
                                _createElement('div', {
                                    'className': 'cm_flex',
                                    'key': '109413'
                                }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                            ] : null, this.ranges.length ? [_createElement.apply(this, [
                                    'div',
                                    {
                                        'className': 'cmRepeater_Values',
                                        'key': '112051'
                                    },
                                    _map(this.Values, repeatValues3.bind(this))
                                ])] : null, [this.Inputs(function () {
                                    return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                            return _createElement('div', {
                                                'placeholder': 'Min price',
                                                'className': 'cm_inputMin'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                            return _createElement('div', {
                                                'placeholder': 'Max price',
                                                'className': 'cm_inputMax'
                                            });
                                        }, {
                                            widgetName: 'undefined',
                                            items: undefined
                                        })], _createElement('button', {
                                        'type': 'button',
                                        'className': 'cm_btn btn btn--secondary',
                                        'onClick': this.setCustomRange
                                    }, '\n    GO\n  '));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]) : null, this.template === 'sliderFacet' ? scopeDollarInchMm4.apply(this, []) : null, this.template === 'reviewFacet' ? _createElement('div', {
                                'className': 'cmTemplate_reviewFacet',
                                'key': '15658'
                            }, _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_ShowAlwaysValues' },
                                _map(this.ShowAlwaysValues, repeatShowAlwaysValues5.bind(this))
                            ]), _createElement.apply(this, [
                                'div',
                                { 'className': 'cmRepeater_Values' },
                                _map(this.Values, repeatValues6.bind(this))
                            ])) : null, this.template === 'thicknessFacet' ? _createElement('div', {
                                'className': 'cmTemplate_thicknessFacet',
                                'key': '24617'
                            }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, '\n    Selected: ', this.selectedRange[0], '" to ', this.selectedRange[1], '"\n  '), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' })))) : null, this.template === 'advancedColorFacet' ? _createElement('div', {
                                'className': 'cmTemplate_advancedColorFacet',
                                'key': '25032'
                            }, _createElement('div', { 'className': 'base-palette' }, this.paletteItems), _createElement('div', { 'className': 'custom-colors-selector' }, _createElement('div', {
                                'className': 'cm_button btn btn--primary',
                                'onClick': this.openSimplePaletteDialog
                            }, _createElement('span', { 'className': 'icon' }, [_createElement('svg', {
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'viewBox': '0 0 512 512',
                                    'key': '253280'
                                }, _createElement('path', { 'd': 'M0 96C0 60.7 28.7 32 64 32l384 0c35.3 0 64 28.7 64 64l0 320c0 35.3-28.7 64-64 64L64 480c-35.3 0-64-28.7-64-64L0 96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6l96 0 32 0 208 0c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z' }))]), '\n    Pick your colors\n  '), _createElement('div', {
                                'className': 'cm_button btn btn--primary',
                                'onClick': this.openColorExtractorDialog
                            }, _createElement('span', { 'className': 'icon' }, [_createElement('svg', {
                                    'xmlns': 'http://www.w3.org/2000/svg',
                                    'viewBox': '0 0 640 512',
                                    'key': '261400'
                                }, _createElement('path', { 'd': 'M144 480C64.5 480 0 415.5 0 336c0-62.8 40.2-116.2 96.2-135.9c-.1-2.7-.2-5.4-.2-8.1c0-88.4 71.6-160 160-160c59.3 0 111 32.2 138.7 80.2C409.9 102 428.3 96 448 96c53 0 96 43 96 96c0 12.2-2.3 23.8-6.4 34.6C596 238.4 640 290.1 640 352c0 70.7-57.3 128-128 128l-368 0zm79-217c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l39-39L296 392c0 13.3 10.7 24 24 24s24-10.7 24-24l0-134.1 39 39c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0l-80 80z' }))]), '\n    Upload Your Color\n  ')), _createElement('div', { 'className': 'cm_color-sliders' }, this.sliders)) : null, this.template === 'sizeFacet' ? _createElement('div', {
                                'className': 'cmTemplate_sizeFacet',
                                'key': '26966'
                            }, [this.WidthFacet(function () {
                                    return _createElement('div', { 'className': 'cm_WidthFacet' }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, _createElement('span', { 'className': 'subsize-title' }, this.name, ': '), !this.isNullRange ? _createElement('span', { 'key': '142' }, this.selectedRange[0], ' - ', this.selectedRange[1]) : null, this.isNullRange ? _createElement('span', { 'key': '235' }, this.selectedRange[0]) : null), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' }))));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })], [this.LengthFacet(function () {
                                    return _createElement('div', { 'className': 'cm_LengthFacet' }, _createElement('div', { 'className': 'facetslider' }, _createElement('div', { 'className': 'facetsliderinfo' }, _createElement('span', { 'className': 'subsize-title' }, this.name, ': '), !this.isNullRange ? _createElement('span', { 'key': '143' }, this.selectedRange[0], ' - ', this.selectedRange[1]) : null, this.isNullRange ? _createElement('span', { 'key': '236' }, this.selectedRange[0]) : null), '\n  ', this.slider, '\n  ', _createElement('div', { 'className': 'cm_slider-bounds' }, _createElement('div', { 'className': 'min' }, this.min), _createElement('div', { 'className': 'max' }, this.max), _createElement('div', { 'className': 'clear' }))));
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]) : null));
                        }, { count: undefined })];
                }
                return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                    'div',
                    { 'className': 'cmRepeater_facets' },
                    _map(this.facets, repeatFacets1.bind(this))
                ]));
            }, {
                widgetName: 'undefined',
                items: undefined
            })])] : null));
}
        export const componentNames = ["cm:filterChips","cm:tooltip","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:WidthFacet","cm:LengthFacet","cm:facets","cm:filterChips","cm:tooltip","cm:filterInput","cm:filterInput","cm:select","cm:inputMin","cm:inputMax","cm:Inputs","cm:inputMin","cm:inputMax","cm:Inputs","cm:WidthFacet","cm:LengthFacet","cm:facets"]