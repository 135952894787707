
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function simplePaletteDialogRT () {
    return _createElement('div', { 'className': 'dialog-container' }, _createElement('div', {
        'className': 'close',
        'data-cm-role': 'close',
        'onClick': this.close
    }, _createElement('svg', {
        'className': 'cm_icon cm_icon-times',
        'height': '20px',
        'role': 'img',
        'viewBox': '0 0 22 22'
    }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))), _createElement('div', { 'className': 'dialog-content' }, _createElement('div', { 'className': 'dialog-header' }, _createElement('div', { 'className': 'dialog-title' }, 'Select a color'), _createElement('div', { 'className': 'buttons-container' }, _createElement('div', {
        'className': 'cm_button cancel btn btn--secondary',
        'onClick': this.close
    }, 'Cancel'), _createElement('div', {
        'className': 'cm_button submit btn btn--primary',
        'onClick': this.submit
    }, 'Ok'))), _createElement('div', { 'className': 'cm_simpleColorPalette dialog-body' }, this.palette, ' ', this.sample), _createElement('div', { 'className': 'dialog-footer' }, _createElement('div', { 'className': 'dialog-footer-content' }, _createElement('div', {
        'className': 'cm_button cancel btn btn--secondary',
        'onClick': this.close
    }, 'Cancel'), _createElement('div', {
        'className': 'cm_button submit btn btn--primary',
        'onClick': this.submit
    }, 'Ok')))));
}
        export const componentNames = []