import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/shopify-generic/Templates/SearchPage.rt'
import { FacetTiles } from 'Components'
import TemplatefacetTilessubtitles from 'Stores/_default-store/Templates/facetTiles/subtitles.rt'
import { FacetPanel } from 'Components'
import { FacetBar } from 'Components'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { VehicleWidget } from 'Components'
import TemplatefitmentSearchcollapsibleVehicleWidget from 'Stores/_default-store/Templates/fitmentSearch/collapsibleVehicleWidget.rt'
import { Garage } from 'Components'
import TemplatefitmentSearchgarage from 'Stores/_default-store/Templates/fitmentSearch/garage.rt'
import { SingleVehicleGarage } from 'Components'
import TemplatefitmentSearchsingleVehicleGarage from 'Stores/_default-store/Templates/fitmentSearch/singleVehicleGarage.rt'
import TemplatefitmentSearchverifyFitment from 'Stores/_default-store/Templates/fitmentSearch/verifyFitment.rt'
import { FitmentTable } from 'Components'
import TemplatefitmentSearchfitmentTable from 'Stores/shopify-generic/Templates/fitmentSearch/fitmentTable.rt'
import TemplatefacetTileshierarchicalContainer from 'Stores/_default-store/Templates/facetTiles/hierarchicalContainer.rt'
import TemplatefacetTilesalphabeticalContainer from 'Stores/_default-store/Templates/facetTiles/alphabeticalContainer.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'
import { ContextDialog } from 'Components'
import TemplatefitmentSearchdialog from 'Stores/_default-store/Templates/fitmentSearch/dialog.rt'
import { SearchHeader } from 'Components'
import { FacetDialog } from 'Components'
import TemplateFacetDialog from 'Stores/_default-store/Templates/FacetDialog.rt'
import { RelatedItems } from 'Components'
import TemplateproductrelatedItems from 'Stores/incredible-rugs-and-decor/Templates/product/relatedItems.rt'
import { SearchResult } from 'Components'
import { RelatedColors } from 'Components'
import TemplateproductrelatedColors from 'Stores/incredible-rugs-and-decor/Templates/product/relatedColors.rt'
import { ProductData } from 'Components'
import TemplateproductproductData from 'Stores/incredible-rugs-and-decor/Templates/product/productData.rt'
import { SimpleColorPaletteDialog } from 'Components'
import TemplatecolorSearchsimplePaletteDialog from 'Stores/_default-store/Templates/colorSearch/simplePaletteDialog.rt'
import { ColorExtractorDialog } from 'Components'
import TemplatecolorSearchcolorExtractorDialog from 'Stores/_default-store/Templates/colorSearch/colorExtractorDialog.rt'

export const compMap = {
  SearchPage,
FacetTiles,
FacetPanel,
FacetBar,
SearchBoxDialogButton,
VehicleWidget,
Garage,
SingleVehicleGarage,
FitmentTable,
SearchBoxDialog,
ContextDialog,
SearchHeader,
FacetDialog,
RelatedItems,
SearchResult,
RelatedColors,
ProductData,
SimpleColorPaletteDialog,
ColorExtractorDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'facetTiles/subtitles': TemplatefacetTilessubtitles,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'fitmentSearch/collapsibleVehicleWidget': TemplatefitmentSearchcollapsibleVehicleWidget,
'fitmentSearch/garage': TemplatefitmentSearchgarage,
'fitmentSearch/singleVehicleGarage': TemplatefitmentSearchsingleVehicleGarage,
'fitmentSearch/verifyFitment': TemplatefitmentSearchverifyFitment,
'fitmentSearch/fitmentTable': TemplatefitmentSearchfitmentTable,
'facetTiles/hierarchicalContainer': TemplatefacetTileshierarchicalContainer,
'facetTiles/alphabeticalContainer': TemplatefacetTilesalphabeticalContainer,
'SearchBoxDialog': TemplateSearchBoxDialog,
'fitmentSearch/dialog': TemplatefitmentSearchdialog,
'FacetDialog': TemplateFacetDialog,
'product/relatedItems': TemplateproductrelatedItems,
'product/relatedColors': TemplateproductrelatedColors,
'product/productData': TemplateproductproductData,
'colorSearch/simplePaletteDialog': TemplatecolorSearchsimplePaletteDialog,
'colorSearch/colorExtractorDialog': TemplatecolorSearchcolorExtractorDialog
};